import React, { useState, useEffect } from 'react';
import {
  CardContent,
  Link,
  Typography,
  useMediaQuery,
  TextField,
  Button,
  Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import { lineLogin, emailLogin, emailSignup } from 'app/redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'app/hooks/useAuth';
import GoogleIcon from '@mui/icons-material/Google';

const Login = () => {
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const error = useSelector(state => state.app.error);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [localError, setLocalError] = useState('');

  const smMatch = useMediaQuery('(max-width: 959px)');

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
  }, [isAuth]);

  useEffect(() => {
    if (error) {
      setLocalError(error);
    }
  }, [error]);

  const handleEmailChange = e => setEmail(e.target.value);
  const handlePasswordChange = e => setPassword(e.target.value);
  const handleConfirmPasswordChange = e => setConfirmPassword(e.target.value);
  const handleSubmit = () => {
    setLocalError('');
    if (isSignup) {
      if (password === confirmPassword) {
        dispatch(emailSignup(email, password));
      } else {
        setLocalError("Passwords don't match");
      }
    } else {
      dispatch(emailLogin(email, password));
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = '/auth/google';
  };

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: smMatch ? '100%' : '25%',
        boxShadow: smMatch ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.1)',
        maxHeight: smMatch ? null : '80vh',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        top: '50%',
        height: smMatch ? 'initial' : '100svh',
      }}>
      <Div sx={{ position: 'relative' }}>
        <img
          src='/images/logo-main.png'
          alt='logo'
          style={{
            width: 75,
            height: 75,
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
        <Div style={{ textAlign: 'center', fontSize: 32 }}>AIMochi</Div>
        <Div style={{ textAlign: 'center', fontSize: 14 }}>welcome back</Div>
      </Div>
      <CardContent sx={{ width: '100%' }}>
        {localError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {localError}
          </Alert>
        )}
        {process.env.REACT_APP_LIFF_ENV === 'development' && (
          <>
            <TextField
              fullWidth
              label='Email'
              variant='outlined'
              value={email}
              onChange={handleEmailChange}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label='Password'
              type='password'
              variant='outlined'
              value={password}
              onChange={handlePasswordChange}
              sx={{ mb: 2 }}
            />
            {isSignup && (
              <TextField
                fullWidth
                label='Confirm Password'
                type='password'
                variant='outlined'
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                sx={{ mb: 2 }}
              />
            )}
            <LoadingButton
              fullWidth
              type='submit'
              variant='contained'
              size='large'
              sx={{
                mb: 1,
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.dark' },
              }}
              onClick={handleSubmit}>
              {isSignup ? '註冊' : '登入'}
            </LoadingButton>
            <Button
              fullWidth
              variant='text'
              size='large'
              sx={{ mb: 1 }}
              onClick={() => {
                setIsSignup(!isSignup);
                setConfirmPassword('');
                setLocalError('');
              }}>
              {isSignup ? '已有帳號？登入' : '沒有帳號？註冊'}
            </Button>
          </>
        )}

        <LoadingButton
          fullWidth
          type='submit'
          variant='contained'
          size='large'
          sx={{
            mt: 2,
            mb: 1,
            backgroundColor: '#02C153',
            '&:hover': { backgroundColor: '#02b14b' },
          }}
          onClick={() => dispatch(lineLogin())}>
          Line Login
        </LoadingButton>
        {process.env.REACT_APP_LIFF_ENV === 'development' && (
          <LoadingButton
            fullWidth
            type='submit'
            variant='contained'
            size='large'
            // startIcon={
            //   <img
            //     src='https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png'
            //     alt='Google logo'
            //     style={{ width: 24, height: 24, objectFit: 'contain' }}
            //   />
            // }
            startIcon={<GoogleIcon />}
            sx={{
              mt: 1,
              mb: 1,
              backgroundColor: '#DB4939',
              color: '#fff',
              textTransform: 'none',
              boxShadow: '0 2px 4px 0 rgba(0,0,0,.25)',
              '&:hover': {
                backgroundColor: '#f8f8f8',
                boxShadow: '0 0 3px 3px rgba(66,133,244,.3)',
              },
            }}
            onClick={handleGoogleLogin}>
            Sign in with Google
          </LoadingButton>
        )}
      </CardContent>
      <Div sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography color='text.secondary' align='center'>
          登入即代表您同意
        </Typography>
        <Link
          href='https://www.aimochi.ai/privacy/'
          underline='none'
          color='primary'
          align='center'
          target='_blank'
          sx={{ mx: 0.5 }}>
          隱私權條款
        </Link>
        <Typography color='text.secondary' align='center'>
          和
        </Typography>
        <Link
          href='https://www.aimochi.ai/rules/'
          underline='none'
          color='primary'
          align='center'
          target='_blank'
          sx={{ mx: 0.5 }}>
          服務條款
        </Link>
      </Div>
    </Div>
  );
};

export default Login;
