import { useEffect, useState } from 'react';
import {
  Modal,
  Fade,
  Backdrop,
  Box,
  Button,
  IconButton,
  Typography,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
  FormHelperText,
} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAlert from 'app/hooks/useAlert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { delNote, shareNote } from 'app/redux/actions/note';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from '../ConfirmModal';
import { useNavigate } from 'react-router-dom';

const ShareNoteModal = ({ noteId, updNotePassword, permission }) => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const note = useSelector(({ search }) => search.getIn(['results', 'note']));
  const labelList = useSelector(({ search }) =>
    search.getIn(['results', 'labels'])
  );
  const userId = useSelector(({ app }) => app.user.id);
  const noteView = useSelector(({ search }) => search.getIn(['config', 'view']));

  const [open, setOpen] = useState(false);
  const [isCheckModalOpen, setIsCheckModalOpen] = useState(false);
  const [notePermission, setNotePermission] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [pwVisible, setPwVisible] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPassword('');
    setError(false);
    setPwVisible(false);
  };

  const handleChange = e => {
    setNotePermission(e.target.value);
    setError(false);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/note/view/${noteId}`
    );
    return showSuccessAlert('複製成功');
  };

  const handlePasswordChange = e => {
    if (error) setError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (notePermission === 'lock' && !password) {
      setError(true);
      return showErrorAlert('請輸入密碼');
    }
    updNotePassword(noteId, password, notePermission);
    handleClose();
    return showSuccessAlert('文章權限更新成功');
  };

  const handlePwVisible = () => {
    setPwVisible(!pwVisible);
  };

  const handleDownloadNote = () => {
    dispatch(shareNote(note, 'TXT', labelList));
    return showSuccessAlert('分享成功');
  };

  const handleCopyNote = () => {
    dispatch(shareNote(note, 'MARKDOWN', null, noteView));
    return showSuccessAlert('複製成功');
  }

  const handleCheckModalOpen = () => {
    setIsCheckModalOpen(true);
  }

  const handleCheckModalClose = () => {
    setIsCheckModalOpen(false);
  }

  const handleDelete = () => {
    dispatch(delNote(userId, noteId));
    navigate('/');
    handleClose();
    return showSuccessAlert('已成功刪除文章');
  };

  useEffect(() => {
    if (permission) {
      setNotePermission(permission);
    }
  }, [permission]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}>
        <Fade in={open}>
          <Box
            component='div'
            sx={{
              position: 'absolute',
              top: '45%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 360,
              bgcolor: 'background.paper',
              boxShadow: 4,
              p: 2,
              borderRadius: '4px',
            }}>
            <Typography
              id='transition-modal-title'
              variant='h4'
              sx={{ textAlign: 'center', mb: 2, fontWeight: 700 }}>
              文章權限
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <FormControl>
              <RadioGroup defaultValue={permission} onChange={handleChange}>
                <FormControlLabel
                  value='private'
                  control={<Radio />}
                  label='私人'
                />
                <FormHelperText sx={{ m: 0, mb: 2 }}>
                  私人的筆記權限，只有自己可以查看
                </FormHelperText>

                <Stack flexDirection='row'>
                  <FormControlLabel
                    value='lock'
                    control={<Radio />}
                    label='加密'
                  />
                  <Box sx={{ ml: 4 }}>
                    <TextField
                      fullWidth
                      type={pwVisible ? 'text' : 'password'}
                      size='small'
                      placeholder='請輸入密碼'
                      value={password}
                      onChange={handlePasswordChange}
                      error={error}
                      InputProps={{
                        style: { padding: 0 },
                        endAdornment: (
                          <IconButton onClick={handlePwVisible}>
                            {pwVisible ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                {error && (
                  <FormHelperText error={error} sx={{ m: 0 }}>
                    {error && '請輸入密碼'}
                  </FormHelperText>
                )}
                <FormHelperText sx={{ m: 0, mt: 1 }}>
                  鎖定的筆記權限，需要輸入密碼才能查看
                </FormHelperText>

                <FormControlLabel
                  value='public'
                  control={<Radio />}
                  label='公開'
                />
                <FormHelperText sx={{ m: 0, mb: 2 }}>
                  公開的筆記權限，並任意向他人分享
                </FormHelperText>
              </RadioGroup>
            </FormControl>
            {notePermission !== 'private' && (
              <Box sx={{ mt: 1, mb: 3 }}>
                <Typography variant='h6' component='div'>
                  分享連結
                </Typography>
                <TextField
                  fullWidth
                  size='small'
                  disabled
                  value={`${window.location.origin}/note/view/${noteId}`}
                  InputProps={{
                    style: { padding: 0 },
                    endAdornment: (
                      <IconButton onClick={handleCopyUrl}>
                        <ContentCopyIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            )}
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
              <Box
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Typography
                  variant='h6'
                  component='div'
                  textAlign='left'
                  sx={{ width: '100%' }}>
                  下載筆記
                </Typography>
                <IconButton
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.086) 0px 5px 10px',
                    fontSize: 16,
                    height: '48px',
                    width: '48px',
                    border: '1px solid #f2f2f2',
                  }}
                  onClick={handleDownloadNote}>
                  <DownloadIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Typography
                  variant='h6'
                  component='div'
                  textAlign='left'
                  sx={{ width: '100%' }}>
                  複製筆記
                </Typography>
                <IconButton
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.086) 0px 5px 10px',
                    fontSize: 16,
                    height: '48px',
                    width: '48px',
                    border: '1px solid #f2f2f2',
                  }}
                  onClick={handleCopyNote}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Typography
                  variant='h6'
                  component='div'
                  textAlign='left'
                  sx={{ width: '100%' }}>
                  刪除筆記
                </Typography>
                <IconButton
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.086) 0px 5px 10px',
                    fontSize: 16,
                    height: '48px',
                    width: '48px',
                    border: '1px solid #f2f2f2',
                  }}
                  onClick={handleCheckModalOpen}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack flexDirection='row' gap={4}>
                <Button variant='contained' onClick={handleSubmit}>
                  確認
                </Button>
                <Button
                  variant='outlined'
                  onClick={handleClose}
                  sx={{ color: '#475259' }}>
                  取消
                </Button>
              </Stack>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <IconButton
        onClick={handleOpen}
        sx={{
          boxShadow: 'rgba(0, 0, 0, 0.086) 0px 5px 10px',
          color: '#475259',
          width: { md: '3.5rem', xs: '2.5rem' },
          height: { md: '3.5rem', xs: '2.5rem' },
        }}>
        <IosShareIcon
          sx={{
            fontSize: { md: '36px', xs: '30px' },
            transform: 'scaleX(-1)',
            cursor: 'pointer',
            color: '#475259',
          }}
        />
      </IconButton>

      <ConfirmModal
        isOpen={isCheckModalOpen}
        title='警告'
        content='確定要刪除這一篇文章？'
        handleClose={handleCheckModalClose}
        handleSubmit={handleDelete}
      />
    </>
  );
};

export default ShareNoteModal;
