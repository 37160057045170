import { Box, Collapse, Divider, Grid, Typography } from '@mui/material';
import {
  clearNote,
  cngNoteView,
  delNoteLabel,
  generateAIContent,
  shareNote,
  updNoteLabel,
  updNoteTitle,
} from 'app/redux/actions/note';
import { fchNote } from 'app/redux/actions/search';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom/dist';
import { debounce } from 'lodash';

import { createLabel } from 'app/redux/actions/label';

import useAlert from 'app/hooks/useAlert';
import ConfirmModal from 'app/shared/ConfirmModal';
import useJumboLayout from '@jumbo/hooks/useJumboLayout';
import TitleArea from 'app/shared/Form/TitleArea';
import IDArea from 'app/shared/Form/IDArea';
import LabelArea from 'app/shared/Form/LabelArea';
import DateArea from 'app/shared/Form/DateArea';
import ActionArea from 'app/shared/Form/ActionArea';
import NoteEditor from 'app/shared/NoteEditor';
import Div from '@jumbo/shared/Div';
import Loading from 'app/shared/Loading';

const NoteEditPage = () => {
  const dispatch = useDispatch();
  const { noteId } = useParams();
  const [searchParams] = useSearchParams();
  const modalType = searchParams.get('modal');
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const { setJumboLayoutOptions } = useJumboLayout();

  const [note, setNote] = useState(null);
  const [labelOption, setLabelOption] = useState(null);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [isImmersive, setIsImmersive] = useState(false);
  const [signalKey, setSignalKey] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const userId = useSelector(({ app }) => app.user.id);
  const labelList = useSelector(({ search }) =>
    search.getIn(['results', 'labels'])
  );
  const selectedLabel = useSelector(({ label }) => label.get('list'));

  const noteData = useSelector(({ search }) =>
    search.getIn(['results', 'note'])
  );

  const noteView = useSelector(({ search }) =>
    search.getIn(['config', 'view'])
  );

  const handleChangeNoteView = view => {
    dispatch(cngNoteView(noteId, view));
  };

  const fchData = async () => {
    const data = await dispatch(fchNote(userId, noteId));
    setNote(data);
  };

  const handleUpdate = async (e, value) => {
    if (e.code === 'Enter') {
      const isDuplicated = labelList.some(item => item.name === e.target.value);
      if (isDuplicated) return showErrorAlert('重複的標籤名稱');
      return dispatch(
        createLabel(userId, e.target.value, note.id, selectedLabel)
      );
    }

    const labels = value.map(label => label.id);
    return dispatch(updNoteLabel(userId, note.id, labels));
  };

  const handleTitleChange = e => {
    setNote({ ...note, title: e.target.value });
    debounceUpdateTitle(userId, note.id, e.target.value);
  };

  const debounceUpdateTitle = useCallback(
    debounce((userId, noteId, value) => {
      dispatch(updNoteTitle(userId, noteId, value));
    }, 500),
    []
  );

  const handleDelLabel = () => {
    dispatch(delNoteLabel(labelOption.user_id, labelOption.id));
    handleDelModalClose();
  };

  const handleDelModalOpen = (e, option) => {
    e.stopPropagation();
    e.preventDefault();

    setLabelOption(option);
    setDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setDelModalOpen(false);
    setLabelOption(null);
  };

  const handleSwitchImmersive = useCallback((e, checked) => {
    setIsImmersive(checked);
    if (window) {
      const delay = setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 300);

      return clearTimeout(delay);
    }
  }, []);

  const stopStreamRequest = () => {
    dispatch(stopStreamRequest(signalKey));
    setSignalKey(null);
  };

  const handleGenerateAIContent = useCallback(
    async type => {
      const isHasGenerated = noteData.hasOwnProperty('generated');
      const generateSize = isHasGenerated ? noteData.generated.length : 0;
      if (isHasGenerated) {
        const isExist = noteData.generated.find(item => item.type === type);
        if (isExist) {
          const index = noteData.generated.findIndex(
            item => item.type === type
          );
          dispatch(cngNoteView(noteId, `generated-${index}`));
          return;
        }
      }

      const signalKey = noteId + '-' + new Date().getTime();
      setSignalKey(signalKey);
      setIsGenerating(true);
      await dispatch(
        generateAIContent(userId, noteId, `${type}-${generateSize}`, signalKey)
      );
      setIsGenerating(false);
      setSignalKey(null);
    },
    [noteData]
  );

  useEffect(() => {
    if (noteId) {
      fchData();
      window.scrollTo(0, 0);
    }
    return () => {
      setNote(null);
      dispatch(clearNote());
    };
  }, [noteId]);

  useEffect(() => {
    if (modalType === 'download' && note) {
      dispatch(shareNote(note, 'TXT', labelList));
      return showSuccessAlert('檔案已下載');
    }
  }, [modalType, note]);

  useEffect(() => {
    setJumboLayoutOptions({ footer: { hide: true } });
  }, []);

  useEffect(() => {
    return () => {
      if (signalKey) {
        stopStreamRequest(signalKey);
      }
    };
  }, []);

  if (!note) return <Loading />;

  return (
    <Box id='note-page-body' component='div'>
      <Grid container sx={{ width: '100%' }}>
        <TitleArea note={note} handleChange={handleTitleChange} />
        <Collapse in={!isImmersive} sx={{ width: '100%' }}>
          <IDArea id={note.id} />
          <DateArea created_at={note.created_at} />
          <LabelArea
            handleUpdate={handleUpdate}
            labelList={labelList}
            selectedLabel={selectedLabel}
            handleDelModalOpen={handleDelModalOpen}
          />
          <ActionArea
            noteView={noteView}
            sourceType={note.source.type}
            url={note.url}
            uId={userId}
            nId={note.id}
            handleChangeNoteView={handleChangeNoteView}
            note={noteData}
            handleGenerateAIContent={handleGenerateAIContent}
          />
        </Collapse>
      </Grid>

      <Divider sx={{ mt: isImmersive ? 0 : 2 }} />

      {isGenerating && (
        <Div
          sx={{
            padding: '16px',
            fontSize: '1.2rem',
            whiteSpace: 'pre-wrap', // 這是關鍵 - 保留換行和空格
            wordBreak: 'break-word', // 確保長文字會換行
            lineHeight: '1.5', // 行高
            fontFamily: 'inherit', // 繼承字體
            p: 6,
            pt: 3,
          }}>
          {noteData.generated &&
            noteData.generated[noteData.generated.length - 1].content}
        </Div>
      )}

      {!isGenerating && (
        <NoteEditor
          sourceType={note.source.type}
          noteView={noteView}
          noteId={noteId}
          userId={userId}
          isImmersive={isImmersive}
          handleSwitchImmersive={handleSwitchImmersive}
        />
      )}

      <ConfirmModal
        title='警告'
        isOpen={delModalOpen}
        handleClose={handleDelModalClose}
        handleSubmit={handleDelLabel}>
        <Typography
          id='transition-modal-title'
          variant='h4'
          component='h2'
          sx={{ mb: 4, textAlign: 'center' }}>
          確認刪除此標籤 <strong>{labelOption?.name}</strong> ？
        </Typography>
        <Typography
          id='transition-modal-title'
          variant='h4'
          component='h2'
          sx={{ mb: 4, textAlign: 'center' }}>
          刪除此標籤後，其餘包含此標籤的文章將會同步移除此標籤
        </Typography>
      </ConfirmModal>
    </Box>
  );
};

export default NoteEditPage;
